<template>
	<canvas ref="chartEl"></canvas>
</template>

<script>
import Chart from "chart.js";
import { onMounted, ref } from "vue";
export default {
	name: "BarChart",
	props: {
		title: {
			required: true,
		},
		data: {
			required: true,
		},
	},
	setup(props) {
		const chartEl = ref(null);

		const setChart = (title, dataObject) => {
			const dataLabels = Object.keys(dataObject);
			const dataValues = Object.values(dataObject);

			const MData = [];
			const FData = [];

			dataValues.forEach((item) => {
				MData.push(item.M);
				FData.push(item.F);
			});

			const data = {
				labels: dataLabels,
				datasets: [
					{
						label: "M",
						backgroundColor: "#63BFCF",
						data: MData,
					},
					{
						label: "F",
						backgroundColor: "#EA757F",
						data: FData,
					},
				],
			};

			const config = {
				type: "bar",
				data: data,
				options: {
					title: {
						display: true,
						text: title,
					},
					barValueSpacing: 15,
					scales: {
						yAxes: [
							{
								ticks: {
									beginAtZero: true,
									userCallback: function (label, index, labels) {
										// when the floored value is the same as the value we have a whole number
										if (Math.floor(label) === label) {
											return label;
										}
									},
								},
							},
						],
					},
					plugins: {
						labels: {
							render: "value",
							fontSize: 12,
						},
					},
				},
			};

			new Chart(chartEl.value, config);
		};

		onMounted(() => {
			setChart(props.title, props.data);
		});

		return {
			chartEl,
			setChart,
		};
	},
};
</script>

<style lang="scss" scoped></style>
