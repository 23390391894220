<template>
	<div class="container">
		<div class="d-flex justify-content-between align-items-center mb-4">
			<h3 class="h3 mb-0">統計資料</h3>
		</div>

		<div class="d-flex align-items-end mb-4">
			<div>
				<label class="mb-2">日期區間</label>
				<Vueform ref="searchForm$" :schema="searchSchema"></Vueform>
			</div>
		</div>
		<div class="mb-4" style="width: 300px">
			<Vueform ref="typeForm$" :schema="typeSchema"></Vueform>
		</div>

		<div v-for="(item, iIndex) in statisticalData" :key="`statistical-${iIndex}`">
			<div v-if="item.type == 'pie'" class="chart-wrap">
				<PieChart v-show="item.title == activeType" :data="item.items" :title="item.title" />
			</div>

			<div v-else-if="item.type == 'bar'" class="chart-wrap">
				<BarChart v-show="item.title == activeType" :data="item.items" :title="item.title" />
			</div>
		</div>
	</div>
</template>

<script>
import Swal from "sweetalert2";
import { computed, onMounted, ref } from "vue";
import { apiStatistical } from "@/assets/js/api.js";
import PieChart from "@/components/Statistical/PieChart.vue";
import BarChart from "@/components/Statistical/BarChart.vue";

export default {
	name: "Statistical",
	components: {
		PieChart,
		BarChart,
	},
	setup() {
		const getData = () => {
			statisticalData.value = [];
			const data = searchForm$.value.data.search;

			apiStatistical(data)
				.then((response) => {
					if (response.data.status == "0") {
						statisticalData.value = response.data.data.map((item) => {
							let type = "";
							let items = {};

							if (item.items[0].hasOwnProperty("M") && item.items[0].hasOwnProperty("F")) {
								type = "bar";
								item.items.forEach((dataItem) => {
									items[dataItem.title] = {
										F: dataItem.F,
										M: dataItem.M,
									};
								});
							} else if (item.items[0].hasOwnProperty("count")) {
								type = "pie";
								item.items.forEach((dataItem) => {
									items[dataItem.title] = dataItem.count;
								});
							}

							return {
								title: item.title,
								type,
								items,
							};
						});

						typeSchema.value.type.items = response.data.data.map((item) => item.title);

						if (activeType.value.length == 0) {
							const firstItem = typeSchema.value.type.items[0];
							typeForm$.value.load({
								type: firstItem,
							});
							activeType.value = firstItem;
						}
					} else {
						Swal.fire({
							icon: "warning",
							text: response.data.message,
						});
					}
				})
				.catch((error) => {
					console.error(error);
					Swal.fire({
						icon: "error",
						text: "CODE ERROR",
					});
				});
		};

		const statisticalData = ref([]);
		const activeType = ref("");

		const searchForm$ = ref(null);
		const searchSchema = ref({
			search: {
				type: "object",
				schema: {
					start_date: {
						type: "date",
						default: "",
						displayFormat: "YYYY-MM-DD",
						loadFormat: "YYYY-MM-DD",
						valueFormat: "YYYY-MM-DD",
						columns: {
							container: 6,
							label: 3,
							wrapper: 12,
						},
						addClasses: {
							ElementAddon: {
								wrapper: "date-element-addon",
							},
						},
						addons: {
							before: " ",
						},
						onChange: getData,
					},
					end_date: {
						label: "到",
						type: "date",
						default: "",
						displayFormat: "YYYY-MM-DD",
						loadFormat: "YYYY-MM-DD",
						valueFormat: "YYYY-MM-DD",
						columns: {
							container: 6,
							label: 1,
							wrapper: 12,
						},
						addClasses: {
							ElementAddon: {
								wrapper: "date-element-addon",
							},
						},
						addons: {
							before: " ",
						},
						onChange: getData,
					},
				},
			},
		});

		const typeForm$ = ref(null);
		const typeSchema = ref({
			type: {
				type: "select",
				native: false,
				canClear: false,
				canDeselect: false,
				search: true,
				items: [],
				default: 0,
				onSelect: (type) => {
					const data = statisticalData.value.find((item) => item.title == type);
					if (data) {
						activeType.value = data.title;
					}
				},
			},
		});

		onMounted(() => {
			getData();
		});

		return {
			searchForm$,
			searchSchema,
			typeForm$,
			typeSchema,
			getData,
			activeType,
			statisticalData,
		};
	},
};
</script>
<style lang="scss" scoped>
.chart-wrap {
	width: 100%;
	max-width: 800px;
}
</style>
