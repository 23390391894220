<template>
	<canvas ref="chartEl"></canvas>
</template>

<script>
import Chart from "chart.js";
import "chartjs-plugin-labels";
import { onMounted, ref } from "vue";

export default {
	name: "BarChart",
	props: {
		title: {
			required: true,
		},
		data: {
			required: true,
		},
	},
	setup(props) {
		const chartEl = ref(null);

		const setChart = (title, dataObject) => {
			const dataLabels = Object.keys(dataObject);
			const dataValues = Object.values(dataObject);

			const colors = ["#5E9CFF", "#975AFE", "#EA757F", "#FDA55A", "#FED654", "#6CC481", "#63BFCF"];

			const backgroundColors = [];
			for (let i in dataLabels) {
				backgroundColors.push(colors[i]);
			}

			const data = {
				labels: dataLabels,
				datasets: [
					{
						backgroundColor: backgroundColors,
						borderColor: "#f2f2f2",
						data: dataValues,
					},
				],
			};

			const config = {
				type: "pie",
				data: data,
				options: {
					title: {
						display: true,
						text: title,
					},
					plugins: {
						labels: {
							render: "value",
							fontSize: 16,
							fontColor: "#fff",
						},
					},
				},
			};

			new Chart(chartEl.value, config);
		};

		onMounted(() => {
			setChart(props.title, props.data);
		});

		return {
			chartEl,
			setChart,
		};
	},
};
</script>

<style lang="scss" scoped></style>
